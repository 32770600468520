@font-face {
  font-family: "Roboto";
  src: local("Roboto"), local("Roboto"),
    url("../public/assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 600;
  src: local("Roboto"), local("Roboto"),
    url("../public/assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: local("Roboto"), local("Roboto"),
    url("../public/assets/fonts/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: light;
  src: local("Helvetica"), local("Helvetica"),
    url("../public/assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), local("Helvetica"),
    url("../public/assets//fonts/Roboto-Regular.ttf")
      format("truetype");
}

.rw-main {
  background: linear-gradient(to bottom right, #313233, #111d24, #041218) !important;
}

html {
  font-size: 16px;
}

.root {
  min-width: 1000px;
  font-family: Rubik, Yantramanav;
  font-size: 14px;
}

.rhs {
  float: right;
  background-color: aliceblue !important;
}

.white_background {
  background-color: white !important;
}

body {
  background-color: black;
  padding: 0px;
  margin: 0px;
}

.MuiMenuItem-root {
  &:hover {
    background-color: #ffc000 !important;
  }
}

.Mui-selected {
  background-color: rgba(255, 193, 0, 0.25) !important;
}

.Mui-focusVisible {
  background-color: inherit !important;
}

/* css available for styling plan features */

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.yellow {
  color: #ffc000;
}
